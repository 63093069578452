import React from "react"
import { Label } from "semantic-ui-react"
import Layout from "../../components/layout"
import "../../styles/packing-list.css"
import { packinglist } from "../../data/student's-corner"
import SEO from "../../components/seo"
function PackingList(props) {
  return (
    <div>
      <Layout>
        <SEO
          title="Packing list"
          description={
            "As you pack your bags for Roorkee with a twinkle in your eyes, trying to anticipate what secrets ahead lie, in the white mansion haunted till date by the ghost of the late James Thomason, fret not, Geek Gazette is here with the ultimate checklist of all essentials you mustn’t forget as you embark on the journey of your college life-"
          }
        />
        <h2 className="dark-color-head">What to Bring?</h2>
        <p className="list-head">
          A list of must haves and things required to help you have a wonderful
          stay at IIT Roorkee!
        </p>
        <div className="legend-box">
          <p className="normal-head">Legend</p>
          <div className="flex-container">
            <div>
              <Label size="large" className="label-blue">
                Blue
              </Label>
              <p className="label">
                Could be brought from home if you own them already
              </p>
            </div>
            <div>
              <Label className="label-red" size="large">
                Red
              </Label>
              <p className="label">Must be brought from home</p>
            </div>
            <div>
              <Label className="label-green" size="large">
                Green
              </Label>
              <p className="label">Best to purchase from Roorkee</p>
            </div>
          </div>
        </div>
        <p className="para">
          As you pack your bags for Roorkee with a twinkle in your eyes, trying
          to anticipate what secrets ahead lie, in the white mansion haunted
          till date by the ghost of the late James Thomason, fret not, Geek
          Gazette is here with the ultimate checklist of all essentials you
          mustn’t forget as you embark on the journey of your college life-
        </p>

        <div>
          {packinglist.map(list => (
            <div className="list">
              <Label size="large" className={`${list.type}`}>
                {list.title}
              </Label>
              <p className="label"> {list.content}</p>
            </div>
          ))}
        </div>
        <p className="para">
          Finally, come to the IITR campus with a curious and receptive mind,
          and with readiness to step out of your comfort zone. As cliché as it
          may sound, these years will be among the best years of your life!
        </p>
      </Layout>
    </div>
  )
}

export default PackingList

/*<div className="legends">
<div>
  <div className="label label-blue">
    Blue
  </div>
  <p>Could be brought from home if you own them already</p>
</div>
<div>
  <div className="label label-red">
    Red
  </div>
  <p>Could be brought from home if you own them already</p>
</div>
<div>
  <div className="label label-green">
    Green
  </div>
  <p>Could be brought from home if you own them already</p>  
</div>           
</div>


      







"<h2>Documents-</h2><p>The most important requirement. Even if you forget everything else at home be sure to carry these.</p><h2>Laptop-</h2><p>Although you can buy a laptop later, it’d be best to have one from the start.</p><p>All the other things that are required can be purchased after coming to IIT-R. On the registration day, stalls are put up inside the campus for bedding and bicycles. And all the other things that you’ll require in your room will be available to purchase right outside the campus.</p>",
*/
